import { ReportType } from '@/models/ReportType';
import { Skill } from '@/models/Skill';
import { AxiosError } from 'axios';
import { Component, Vue, Model, Prop } from 'vue-property-decorator';
import { Answer, Report as ReportModel, Tag } from '@/models/Report';
import { DateFormatter } from '@/support/DateFormatter';
import { Rpc } from '@/models/Rpc';
import { Options } from '@/components/mi-dialog/MiDialog';
import ErrorHandler from '@/support/ErrorHandler';
import { cloneDeep } from 'lodash';

@Component<DataQuality>({})
export default class DataQuality extends Vue {
  public $pageTitle = 'Data Quality';

  @Model('report', { default: () => new ReportModel() })
  protected report!: ReportModel;

  @Prop({ default: true })
  protected allowEdit!: boolean;

  @Prop({ default: false })
  protected syncing!: boolean;

  protected isLoading = true;

  protected data: Data = {};

  protected reportTags: Tag[] = [];

  protected skills: Skill[] = [];

  protected selectedSkills: Skill[] = [];

  protected reportTypes: ReportType[] = [];

  protected submittedAt: DateFormatter = new DateFormatter();

  protected eigendomsinds: DateFormatter = new DateFormatter();

  protected async getSkills(): Promise<void> {
    this.skills = await new Skill()
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected async getReportTypes() {
    this.reportTypes = await new ReportType()
      .filter({
        is_creatable: true,
      })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected removeSkill(index: number) {
    if (this.selectedSkills) {
      this.selectedSkills.splice(index, 1);
    }
  }

  protected saveReportTags(tags: string[]) {
    this.data.tags = tags;
  }

  protected async mounted() {
    await this.getSkills();
    await this.getReportTypes();

    this.isLoading = true;
    this.data.year = this.findReportAnswer('BouwJaar');
    this.data.contact = this.findReportAnswer('NaamContactpersoon');
    this.data.organization_aanvrager = this.report.applicant?.company || this.report.intermediary?.company || '';
    this.eigendomsinds.selectedDate = this.findReportAnswer('EigendomSinds') === '0000-00-00 00:00:00' ? '' : (this.findReportAnswer('EigendomSinds') || '');
    this.submittedAt.selectedDate = this.report.submitted_at === '0000-00-00 00:00:00' ? '' : (this.report.submitted_at || '');
    this.data.special = this.report.is_special;
    this.data.case_number = this.report.case_number;
    this.data.tags = this.report.tags?.map((tag: Tag) => tag.id || '');
    this.selectedSkills = cloneDeep(this.report.skills) || [];
    this.data.type = this.report.type?.uuid || '';
    this.isLoading = false;
  }

  protected findReportAnswer(anwerKey: string) {
    if (! this.report.answers) { return ''; }

    const answer = this.report.answers?.find((answer: Answer) => answer.key === anwerKey);

    return answer ? answer.value : '';
  }

  protected async update() {
    if (! this.report) {
      return;
    }

    this.isLoading = true;

    const payload: DataRPCPayload = {
      signature: 'bitool:update',
      body: {
        report: this.report.uuid,
        year: this.data.year,
        submitted_at: this.submittedAt.selectedDate === 'Invalid DateTime' ? '' : (this.submittedAt.selectedDate || ''),
        eigendom_sinds: this.eigendomsinds.selectedDate === 'Invalid DateTime' ? '' : (this.eigendomsinds.selectedDate || ''),
        organization_aanvrager: this.data.organization_aanvrager,
        contact: this.data.contact,
        case_number: this.data.case_number,
        special: this.data.special,
        tags: this.data.tags,
        skills: this.selectedSkills.map((skill: Skill) => skill.id || ''),
        type: this.data.type || '',
      },
    };

    await new Rpc()
      .rpcPost(payload)
      .then(() => {
        this.$store.dispatch('openDialog', this.dialogOptionsReportConfirm);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.isLoading = false;
  }

  protected get dialogOptionsReportConfirm(): Options {
    return {
      title: 'Gelukt!',
      text: 'Het opslaan is gelukt.',
      type: 'success',
      buttons: {
        confirm: {
          text: 'ok',
          action: () => {
            this.$emit('reload');
          },
        },
      },
    };
  }
}

interface DataRPCPayload {
  signature: string;
  body: DataPayload;
}

interface DataPayload {
  report?: string;
  year?: string;
  eigendom_sinds?: string;
  organization_aanvrager?: string;
  contact?: string;
  submitted_at?: string;
  case_number?: string;
  special?: boolean;
  tags?: string[];
  type?: string;
  skills?: string[];
}

interface Data {
  report?: string;
  year?: string;
  eigendom_sinds?: string;
  organization_aanvrager?: string;
  contact?: string;
  submitted_at?: string;
  case_number?: string;
  special?: boolean;
  tags?: string[];
  type?: string;
  skills?: Skill[];
}

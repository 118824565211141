var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.report
    ? _c(
        "div",
        { staticClass: "elementPanel" },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _vm.isLoading
                    ? _c("LoaderCard", {
                        attrs: {
                          flat: "",
                          type: "spinner--center",
                          minHeight: "300px"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          !_vm.isLoading
            ? [
                _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c(
                          "v-layout",
                          {
                            staticClass: "item__list item__list--reports",
                            attrs: { "mt-4": "", wrap: "" }
                          },
                          [
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "", "align-center": "" } },
                                  [
                                    _c("v-flex", { attrs: { xs4: "" } }, [
                                      _c("span", [_vm._v("Gewenste bouwjaar")])
                                    ]),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs8: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Bouwjaar",
                                            "hide-detail": ""
                                          },
                                          model: {
                                            value: _vm.data.year,
                                            callback: function($$v) {
                                              _vm.$set(_vm.data, "year", $$v)
                                            },
                                            expression: "data.year"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "", "align-center": "" } },
                                  [
                                    _c("v-flex", { attrs: { xs4: "" } }, [
                                      _c("span", [_vm._v("Indieningsdatum")])
                                    ]),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs8: "" } },
                                      [
                                        _c(
                                          "v-menu",
                                          {
                                            ref: "dialog",
                                            attrs: {
                                              "close-on-content-click": true,
                                              lazy: "",
                                              "nudge-right": "100",
                                              "full-width": "",
                                              "max-width": "290px",
                                              "min-width": "290px"
                                            }
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticClass: "input input-date",
                                              attrs: {
                                                slot: "activator",
                                                placeholder: "DD-MM-YYYY",
                                                label: "Indieningsdatum"
                                              },
                                              slot: "activator",
                                              model: {
                                                value:
                                                  _vm.submittedAt.formattedDate,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.submittedAt,
                                                    "formattedDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "submittedAt.formattedDate"
                                              }
                                            }),
                                            _c("v-date-picker", {
                                              ref: "picker",
                                              attrs: {
                                                "first-day-of-week": "1",
                                                locale: "nl-nl",
                                                min: "1910-01-01",
                                                color: "#837f16"
                                              },
                                              model: {
                                                value:
                                                  _vm.submittedAt.selectedDate,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.submittedAt,
                                                    "selectedDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "submittedAt.selectedDate"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "", "align-center": "" } },
                                  [
                                    _c("v-flex", { attrs: { xs4: "" } }, [
                                      _c("span", [_vm._v("Eigendom sinds")])
                                    ]),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs8: "" } },
                                      [
                                        _c(
                                          "v-menu",
                                          {
                                            ref: "dialog",
                                            attrs: {
                                              "close-on-content-click": true,
                                              lazy: "",
                                              "nudge-right": "100",
                                              "full-width": "",
                                              "max-width": "290px",
                                              "min-width": "290px"
                                            }
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticClass: "input input-date",
                                              attrs: {
                                                slot: "activator",
                                                placeholder: "DD-MM-YYYY",
                                                label: "Eigendom sinds"
                                              },
                                              slot: "activator",
                                              model: {
                                                value:
                                                  _vm.eigendomsinds
                                                    .formattedDate,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.eigendomsinds,
                                                    "formattedDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "eigendomsinds.formattedDate"
                                              }
                                            }),
                                            _c("v-date-picker", {
                                              ref: "picker",
                                              attrs: {
                                                "first-day-of-week": "1",
                                                locale: "nl-nl",
                                                min: "1910-01-01",
                                                color: "#837f16"
                                              },
                                              model: {
                                                value:
                                                  _vm.eigendomsinds
                                                    .selectedDate,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.eigendomsinds,
                                                    "selectedDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "eigendomsinds.selectedDate"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "", "align-center": "" } },
                                  [
                                    _c("v-flex", { attrs: { xs4: "" } }, [
                                      _c("span", [
                                        _vm._v("Organisatie aanvrager")
                                      ])
                                    ]),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs8: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Organisatie aanvrager",
                                            "hide-detail": ""
                                          },
                                          model: {
                                            value:
                                              _vm.data.organization_aanvrager,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.data,
                                                "organization_aanvrager",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "data.organization_aanvrager"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "", "align-center": "" } },
                                  [
                                    _c("v-flex", { attrs: { xs4: "" } }, [
                                      _c("span", [
                                        _vm._v("Naam contactpersoon")
                                      ])
                                    ]),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs8: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Naam contactpersoon",
                                            "hide-detail": ""
                                          },
                                          model: {
                                            value: _vm.data.contact,
                                            callback: function($$v) {
                                              _vm.$set(_vm.data, "contact", $$v)
                                            },
                                            expression: "data.contact"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "", "align-center": "" } },
                                  [
                                    _c("v-flex", { attrs: { xs4: "" } }, [
                                      _c("span", [_vm._v("Zaaknummer")])
                                    ]),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs8: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Zaaknummer",
                                            "hide-detail": ""
                                          },
                                          model: {
                                            value: _vm.data.case_number,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.data,
                                                "case_number",
                                                $$v
                                              )
                                            },
                                            expression: "data.case_number"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "", "align-center": "" } },
                                  [
                                    _c("v-flex", { attrs: { xs4: "" } }, [
                                      _c("span", [_vm._v("Rapporttype")])
                                    ]),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs8: "" } },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.reportTypes,
                                            "hide-details": "",
                                            label: "Report type",
                                            "item-text": "name",
                                            "item-value": "uuid"
                                          },
                                          model: {
                                            value: _vm.data.type,
                                            callback: function($$v) {
                                              _vm.$set(_vm.data, "type", $$v)
                                            },
                                            expression: "data.type"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "", "align-center": "" } },
                                  [
                                    _c("v-flex", { attrs: { xs4: "" } }, [
                                      _c("span", [_vm._v("Special")])
                                    ]),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs8: "" } },
                                      [
                                        _c("v-checkbox", {
                                          staticClass: "mt-2",
                                          attrs: {
                                            label: "Special boolean",
                                            color: "secondary",
                                            "hide-details": ""
                                          },
                                          model: {
                                            value: _vm.data.special,
                                            callback: function($$v) {
                                              _vm.$set(_vm.data, "special", $$v)
                                            },
                                            expression: "data.special"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "", "align-center": "" } },
                                  [
                                    _c("v-flex", { attrs: { xs4: "" } }, [
                                      _c("span", [_vm._v("Tags")])
                                    ]),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs8: "" } },
                                      [
                                        _c("ReportTags", {
                                          attrs: {
                                            autoSave: false,
                                            reportId: _vm.report.uuid,
                                            reportTags: _vm.report.tags,
                                            isEditing: true
                                          },
                                          on: {
                                            tagsCreated: _vm.saveReportTags
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "", "align-center": "" } },
                                  [
                                    _c("v-flex", { attrs: { xs4: "" } }, [
                                      _c("span", [_vm._v("Competenties")])
                                    ]),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs8: "" } },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.skills,
                                            "item-text": "name",
                                            "item-value": "id",
                                            "hide-details": "",
                                            placeholder: "Competenties",
                                            clearable: "",
                                            multiple: "",
                                            "return-object": ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "selection",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  var index = ref.index
                                                  return [
                                                    index === 0
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(item.name) +
                                                              " "
                                                          )
                                                        ])
                                                      : _vm._e(),
                                                    index === 1
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "grey--text caption"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " (+" +
                                                                _vm._s(
                                                                  _vm
                                                                    .selectedSkills
                                                                    .length - 1
                                                                ) +
                                                                " andere) "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            1005106344
                                          ),
                                          model: {
                                            value: _vm.selectedSkills,
                                            callback: function($$v) {
                                              _vm.selectedSkills = $$v
                                            },
                                            expression: "selectedSkills"
                                          }
                                        }),
                                        _c(
                                          "v-layout",
                                          {
                                            attrs: {
                                              row: "",
                                              wrap: "",
                                              "align-content-start": "",
                                              "align-start": "",
                                              "justify-start": ""
                                            }
                                          },
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass: "chips",
                                                attrs: {
                                                  s12: "",
                                                  "align-content-start": "",
                                                  "align-start": "",
                                                  "justify-start": ""
                                                }
                                              },
                                              _vm._l(
                                                _vm.selectedSkills,
                                                function(skill, index) {
                                                  return _c(
                                                    "v-chip",
                                                    {
                                                      key: index,
                                                      attrs: { close: "" },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return _vm.removeSkill(
                                                            index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(skill.name)
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", "text-xs-right": "" } },
                      [
                        _c(
                          "MiButton",
                          {
                            staticClass: "mr-1",
                            attrs: {
                              outline: "true",
                              icon: "save",
                              small: "true",
                              color: "primary"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.update($event)
                              }
                            }
                          },
                          [_vm._v(" Updaten ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }